import { Box, Button, useMediaQuery } from "@mui/material"

import LandingContentComponent from "../component/landing/landing-content.component"

const LandingScreen = () => {

    const isSmallScreen = useMediaQuery('(max-width:600px)');


    return (

        <div className={`${isSmallScreen ? "container-fluid container-xxxl" : ""}`} style={{ padding: 0 }} >
            <LandingContentComponent />
        </div>

    )
}

export default LandingScreen
