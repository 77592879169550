import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './router';
import { history } from "./router/history";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createMuiTheme } from '@mui/material';
import MainScreen from './layout/main-layout';


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'DIN W01 Light',
      'Calibri',
      'Helvetica Nue',
      "Arial",
      "sans-serif"
    ].join(','),
  }
});

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter history={history}>
            <MainScreen>
              <Routes />
            </MainScreen>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
