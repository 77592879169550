
import LandingScreen from "../screen/LandingScreen";


export const routes =[
    {
        path: "/",
        name: "LandingScreen",
        element: <LandingScreen/>,
        isPrivate: false
    },
    
]
