import { Box } from "@mui/material"
import LandingBannerComponent from "./landing-banner.component"

const LandingContentComponent = () => {

    return(

        <Box>
            <LandingBannerComponent />
        </Box>

    )
}

export default LandingContentComponent